import { useContext } from 'react';

import { ToasterContext } from './ToasterContext';
import { ToasterContextState } from './ToasterContextState';

export const useToaster = (): ToasterContextState => {
  const context = useContext(ToasterContext);
  if (context === undefined) {
    throw new Error('useToaster must be used within a ToasterProvider');
  }
  return context;
};
